import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./App.css";
import Amplify, { Hub, Auth } from "aws-amplify";
import {
	AmplifyAuthenticator,
	AmplifySignIn,
	AmplifySignUp,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import {
	createMuiTheme,
	ThemeProvider,
	useTheme,
} from "@material-ui/core/styles";
import config from "./aws-exports";
import { I18n } from "@aws-amplify/core";
import { dict } from "./I18n";
import Bar from "./components/Bar";
import Container from "@material-ui/core/Container";
import DateFnsUtils from "@date-io/date-fns";
import fiLocale from "date-fns/locale/fi";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MeasurementForm from "./components/MeasurementForm";
import { Measurement, Moment, Tracking } from "./models";
import { DataStore, SortDirection } from "@aws-amplify/datastore";
import { Backdrop, Card, Typography, useMediaQuery } from "@material-ui/core";
import MeasurementTable from "./components/MeasurementTable";
import Chart from "./components/Chart";
import Grid from "@material-ui/core/Grid";
import PdfDocument from "./components/PdfDocument";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "@material-ui/core/colors";
import Footer from "./components/Footer";
import { formatDate } from "./functions";
import AverageMeasurementCard from "./components/AverageMeasurementCard";
import Logo from "./Apteq_logo_lg.png";

const Theme = createMuiTheme({
	palette: {
		primary: {
			main: grey[900],
		},
		secondary: {
			main: "#4e976f",
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 500,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
});

Amplify.configure(config);
Auth.configure(config);
I18n.putVocabularies(dict);
I18n.setLanguage("fi");

type AverageMeasurement = {
	systolic: number;
	diastolic: number;
};

export type AverageMeasurements = {
	morning: AverageMeasurement;
	evening: AverageMeasurement;
	all: AverageMeasurement;
};

function App() {
	const [authState, setAuthState] = useState<AuthState>();
	const [user, setUser] = React.useState<object | undefined>();
	const [trackingList, setTrackingList] = useState<Tracking[]>();
	const [currentTracking, setCurrentTracking] = useState<Tracking | null>(null);
	const [measurements, setMeasurements] = useState<Measurement[]>([]);
	const [chartElement, setChartElement] = useState<HTMLElement | null>(null);
	const [measurementsLoading, setMeasurementsLoading] = useState<boolean>(
		false
	);

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));

	const fetchTrackingList = useCallback(() => {
		(async () => {
			try {
				const trackingList = (await DataStore.query(Tracking)).reverse();
				if (!trackingList.length) setTrackingList([]);
				setTrackingList(trackingList);
				setCurrentTracking(trackingList[0]);
			} catch (e) {
				console.error(e);
			}
		})();
	}, [setCurrentTracking]);

	useEffect(() => {
		onAuthUIStateChange(async (nextAuthState, authData) => {
			setAuthState(nextAuthState);
			setUser(authData);
			if (nextAuthState === AuthState.SignedIn) {
				await DataStore.start();
				const removeListener = Hub.listen("datastore", async (capsule) => {
					const {
						payload: { event },
					} = capsule;
					if (event === "ready") {
						fetchTrackingList();
						removeListener();
					}
				});
			}
		});
	}, [fetchTrackingList]);

	const fetchMeasurements = useCallback(() => {
		(async () => {
			try {
				if (currentTracking) {
					setMeasurementsLoading(true);
					const measurementList = await DataStore.query(
						Measurement,
						(m) => m.trackingId("eq", currentTracking.id),
						{
							sort: (s) =>
								s
									.date(SortDirection.ASCENDING)
									.moment(SortDirection.DESCENDING),
						}
					);
					setMeasurements(measurementList);
					setMeasurementsLoading(false);
				}
			} catch (e) {
				setMeasurementsLoading(false);
				console.error(e);
			}
		})();
	}, [currentTracking]);

	useEffect(() => {
		fetchMeasurements();
	}, [fetchMeasurements]);

	const averageMeasurements = useMemo<AverageMeasurements>(() => {
		const { morning, evening, all } = measurements.reduce(
			({ morning, evening, all }, current) => {
				return {
					morning:
						current.moment === Moment.MORNING
							? {
									systolic: morning.systolic + current.averageSample.systolic,
									diastolic:
										morning.diastolic + current.averageSample.diastolic,
									count: morning.count + 1,
							  }
							: morning,
					evening:
						current.moment === Moment.EVENING
							? {
									systolic: evening.systolic + current.averageSample.systolic,
									diastolic:
										evening.diastolic + current.averageSample.diastolic,
									count: evening.count + 1,
							  }
							: evening,
					all: {
						systolic: all.systolic + current.averageSample.systolic,
						diastolic: all.diastolic + current.averageSample.diastolic,
						count: all.count + 1,
					},
				};
			},
			{
				morning: { systolic: 0, diastolic: 0, count: 0 },
				evening: { systolic: 0, diastolic: 0, count: 0 },
				all: { systolic: 0, diastolic: 0, count: 0 },
			}
		);

		return {
			morning: morning.count
				? {
						systolic: Math.round(morning.systolic / morning.count),
						diastolic: Math.round(morning.diastolic / morning.count),
				  }
				: { systolic: 0, diastolic: 0 },
			evening: evening.count
				? {
						systolic: Math.round(evening.systolic / evening.count),
						diastolic: Math.round(evening.diastolic / evening.count),
				  }
				: { systolic: 0, diastolic: 0 },
			all: all.count
				? {
						systolic: Math.round(all.systolic / all.count),
						diastolic: Math.round(all.diastolic / all.count),
				  }
				: { systolic: 0, diastolic: 0 },
		};
	}, [measurements]);

	return authState === AuthState.SignedIn && user ? (
		<ThemeProvider theme={Theme}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
				<div className="App">
					<Bar
						trackingList={trackingList}
						fetchTrackingList={fetchTrackingList}
						currentTracking={currentTracking}
						setCurrentTracking={setCurrentTracking}
					/>
					<Container maxWidth="xl" style={{ paddingBottom: 80 }}>
						{currentTracking ? (
							<Grid container>
								<MeasurementForm
									reFetch={fetchMeasurements}
									currentTracking={currentTracking}
								/>
								{measurements.length && !measurementsLoading ? (
									<PdfDocument
										trackingName={currentTracking.name}
										measurements={measurements}
										chart={chartElement}
										averageMeasurements={averageMeasurements}
									/>
								) : null}
								<Grid item container spacing={1}>
									{measurementsLoading ? (
										<Backdrop open={measurementsLoading}>
											<CircularProgress color="inherit" />
										</Backdrop>
									) : measurements.length ? (
										<>
											<Grid item lg={6} md={12} xs={12}>
												<Card
													style={{
														margin: 5,
														height: matches ? "100%" : "75vh",
													}}
												>
													<Chart
														title={`${currentTracking.name} ${formatDate(
															currentTracking.startDate
														)} - ${formatDate(currentTracking.endDate)}`}
														measurements={measurements}
														setChartElement={setChartElement}
													/>
												</Card>
											</Grid>
											<Grid item lg={6} md={12} xs={12}>
												<Card
													style={{
														margin: 5,
														height: matches ? "100%" : "75vh",
													}}
												>
													<Grid
														container
														direction={"column"}
														style={{ height: "100%" }}
													>
														<Grid container justify={"space-around"}>
															<AverageMeasurementCard
																title={"Kaikkien mittausten keskiarvot"}
																systolic={averageMeasurements.all.systolic}
																diastolic={averageMeasurements.all.diastolic}
															/>
															<AverageMeasurementCard
																title={"Aamujen keskiarvot"}
																systolic={averageMeasurements.morning.systolic}
																diastolic={
																	averageMeasurements.morning.diastolic
																}
															/>
															<AverageMeasurementCard
																title={"Iltojen keskiarvot"}
																systolic={averageMeasurements.evening.systolic}
																diastolic={
																	averageMeasurements.evening.diastolic
																}
															/>
														</Grid>
														<MeasurementTable
															reFetch={fetchMeasurements}
															measurements={measurements}
															currentTracking={currentTracking}
														/>
													</Grid>
												</Card>
											</Grid>
										</>
									) : (
										<Grid item>
											<Typography>
												Et ole tallentanut yhtään mittaustulosta
											</Typography>
										</Grid>
									)}
								</Grid>
							</Grid>
						) : (
							<Typography>
								{trackingList && "Luo seuranta lisätäksesi mittaustuloksia."}
							</Typography>
						)}
					</Container>
					<Footer />
				</div>
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	) : (
		<AmplifyAuthenticator usernameAlias="email">
			<AmplifySignUp
				slot="sign-up"
				usernameAlias="email"
				formFields={[
					{
						type: "email",
						label: I18n.get("Email Address *"),
						placeholder: I18n.get("Enter your email address"),
						required: true,
					},
					{
						type: "password",
						label: I18n.get("Password"),
						placeholder: I18n.get("Enter your password"),
						required: true,
					},
				]}
			/>
			<Grid slot="sign-in" container direction={"column"} alignItems={"center"}>
				<Grid item container direction={"column"} alignItems={"center"}>
					<Grid
						item
						container
						direction={"column"}
						alignItems={"center"}
						style={{ marginTop: 16, marginBottom: 32 }}
					>
						<a href="https://apteq.fi/" target={"_blank"} rel="noreferrer">
							<img src={Logo} alt={""} style={{ maxWidth: "100%" }} />
						</a>
						<Typography variant={"h5"}>Sähköinen verenpainekortti</Typography>
					</Grid>
					<Grid item justify={"center"} alignItems={"center"} style={{marginBottom: 32}}>
						<Typography variant={"h6"} style={{ maxWidth: 700 }}>
							Suljemme seurantakortti.fi -palvelun 31.12.2024 ja poistamme
							kaikki palvelun sisältämät tiedot.
						</Typography>
						<Typography variant={"h6"} style={{ maxWidth: 700 }}>
							Halutessasi voit ottaa omat tietosi / mittaustuloksesi talteen
							ennen tätä päivää.
							Helpoiten teet sen kirjautumalla palveluun ja lataamalla tiedot
							PDF-muodossa.
						</Typography>
						<Typography variant={"h6"}>
							Lisätiedot:&nbsp;
							<a href="mailto:asiakaspalvelu@fennogate.fi">
								asiakaspalvelu@fennogate.fi
							</a>
						</Typography>
					</Grid>
					<Grid item>
						<AmplifySignIn
							usernameAlias="email"
							formFields={[
								{
									type: "email",
									label: I18n.get("Email Address *"),
									placeholder: I18n.get("Enter your email address"),
									required: true,
								},
								{
									type: "password",
									label: I18n.get("Password"),
									placeholder: I18n.get("Enter your password"),
									required: true,
								},
							]}
						/>
					</Grid>
				</Grid>
				<Grid container direction={"column"} alignItems={"center"}>
					<Grid item container justify={"center"}>
						Käyttämällä palvelua hyväksyt palvelun&nbsp;
						<a
							href="https://apteq.fi/wp-content/uploads/Seurantakortti_kayttoehdot.pdf"
							target={"_blank"}
							rel="noreferrer"
						>
							käyttöehdot.
						</a>
					</Grid>
					<Grid item>
						Lataa käyttöohje&nbsp;
						<a
							href="https://apteq.fi/wp-content/uploads/seurantakorttiohje_web.pdf"
							target={"_blank"}
							rel="noreferrer"
						>
							tästä.
						</a>
					</Grid>
				</Grid>
			</Grid>
		</AmplifyAuthenticator>
	);
}

export default App;
